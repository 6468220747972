import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";

function App() {

  const [shares, setShares] = useState([]);
  const [sharesData, setSharesData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [startDate, setStartDate] = useState(() => (new Date(Date.now() + ((5 * 60 + 30) * 60 * 1000))).toISOString().substring(0, 10));
  const [endDate, setEndDate] = useState(() => (new Date(Date.now() + (15 * 86400000) + ((5 * 60 + 30) * 60 * 1000))).toISOString().substring(0, 10));
  const [fromDays, setFromDays] = useState(0);
  const [toDays, setToDays] = useState(15);

  useEffect(() => {
    // refresh();
  }, [refreshData]);

  const refresh = () => {

    const apiUrl = "https://www.nseindia.com/api/corporates-corporateActions?index=equities&from_date=17-07-2021&to_date=1-08-2021";
    /*  var apiParam = {
       method: 'POST',
       headers: { startDate: (new Date((new Date(startDate)) - ((5 * 60 + 30) * 60 * 1000))).toISOString(), endDate: (new Date((new Date(endDate)) - ((-18 * 60 - 30) * 60 * 1000))).toISOString() }
     }; */
    fetch("https://www.nseindia.com", {
      credentials: 'include',
      accept: '*/*',
    })
      .then(res => alert(JSON.stringify(res)))
      .then(body => {
        alert(JSON.stringify(body))
        //setShares(body.data);
      })
      .catch(err => alert(JSON.stringify(err)));

  };

  const updateShares = (obj) => {
    var data = obj.filter(item => item.subject.toLowerCase().includes('dividend'));
    setSharesData([]);
    setHistoryData([]);
    setRefreshData(!refreshData);
    setShares(data);
  }
  const updateSharesData = (idx, obj) => {
    var data = sharesData;
    if (obj.metadata.symbol !== shares[idx].symbol) {
      alert("Company Mismatch!")
      data[idx] = null;
    }
    else {
      data[idx] = obj;
    }
    setSharesData(data);
    setRefreshData(!refreshData);
  }

  const updateHistoryData = (idx, obj) => {
    var data = historyData;
    if (obj[0].symbol !== shares[idx].symbol) {
      alert("Company Mismatch!")
      data[idx] = null;
    }
    else {
      data[idx] = obj;
    }
    setHistoryData(data);
    setRefreshData(!refreshData);
  }


  const createHistory = (idx) => {
    var html = "<table style='border: 1px solid white; background-color: #282c34;min-height: 100vh;display: flex;flex-direction: column;align-items: center;justify-content: flex-start;font-size: 16px;color: white;'><tr><td>Date</td><td>Dividend</td><td>Action</td></tr>";
    historyData[idx].map((row, key) => {
      html += "<tr><td>" + row.exDate + "</td><td>"
        + parseFloat(parseFloat(row.subject.split(' Per ').length > 1 ? row.subject.split(' Per ')[0].trim().substring(row.subject.split(' Per ')[0].trim().lastIndexOf(' ')) : "0")
          + parseFloat(row.subject.split(' Per ').length > 2 ? row.subject.split(' Per ')[1].trim().substring(row.subject.split(' Per ')[1].trim().lastIndexOf(' ')) : "0")
          + parseFloat(row.subject.split(' Per ').length > 3 ? row.subject.split(' Per ')[2].trim().substring(row.subject.split(' Per ')[2].trim().lastIndexOf(' ')) : "0")) + "</td><td>" + row.subject + "</td></tr>"
    });
    html += "</table>";
    return html;
  }

  const showHistory = (idx) => {
    var win = window.open("", "", "addressbar=no,toolbar=no,location=no,directories=no,status=no,menubar=no");
    win.document.body.innerHTML = createHistory(idx);
    win.document.title = shares[idx].comp;
  }
  const showResearch = (idx) => {
    var win = window.open("https://money.rediff.com/companies/" + shares[idx].comp.split('Limited')[0], "", "addressbar=no,toolbar=no,location=no,directories=no,status=no,menubar=no");
  }

  const calculateROI = (price, subject) => {
    return (((100 *
      (parseFloat(subject ? subject.split(' Per ').length > 1 ? subject.split(' Per ')[0].trim().substring(subject.split(' Per ')[0].trim().lastIndexOf(' ')) : "0" : "") +
        parseFloat(subject ? subject.split(' Per ').length > 2 ? subject.split(' Per ')[1].trim().substring(subject.split(' Per ')[1].trim().lastIndexOf(' ')) : "0" : "") +
        parseFloat(subject ? subject.split(' Per ').length > 3 ? subject.split(' Per ')[2].trim().substring(subject.split(' Per ')[2].trim().lastIndexOf(' ')) : "0" : "")))
      / parseFloat(price)).toFixed(2))
  }

  return (
    <div className="App">
      From Days:<input type='number' value={fromDays}
        onChange={(e) => {
          setFromDays(e.target.value);
          setStartDate(new Date(Date.now() + (parseInt(e.target.value) * 86400000) + ((5 * 60 + 30) * 60 * 1000)).toISOString().substring(0, 10));
          setRefreshData(!refreshData);
        }} />&nbsp;&nbsp;&nbsp;&nbsp;
      To Days:<input type='number' value={toDays} onChange={(e) => {
        setToDays(e.target.value);
        setEndDate(new Date(Date.now() + (parseInt(e.target.value) * 86400000) + ((5 * 60 + 30) * 60 * 1000)).toISOString().substring(0, 10));
        setRefreshData(!refreshData);
      }} />
      <a style={{ color: 'red' }} href={"https://www.nseindia.com/api/corporates-corporateActions?index=equities&from_date=" + startDate.split('-')[2] + '-' + startDate.split('-')[1] + '-' + startDate.split('-')[0] + "&to_date=" + endDate.split('-')[2] + '-' + endDate.split('-')[1] + '-' + endDate.split('-')[0]} target="_blank">Show Shares</a>
      <input value={JSON.stringify(shares)} onChange={(e) => updateShares(JSON.parse(e.target.value))}></input>
      <header className="App-header">
        <table>
          <tr>
            <td>Company</td>
            <td>Data</td>
            <td>Price</td>
            <td>Dividend</td>
            <td>ROI %</td>
            <td>Action</td>
            <td>History</td>
            <td>Industry</td>
            <td>Share P/E</td>
            <td>Sector P/E</td>
            <td>Face Value</td>
            <td>Date</td>
            <td>Listed On</td>
          </tr>
          {shares.map((row, key) => {
            return (<tr>
              <td><a style={{ color: 'white' }} href={"https://www.nseindia.com/api/quote-equity?symbol=" + row.symbol} target="_blank">{row.comp}</a><br />{(sharesData[key] || { priceInfo: { "weekHighLow": { min: "" } } }).priceInfo.weekHighLow.min}({(sharesData[key] || { priceInfo: { "weekHighLow": { minDate: "" } } }).priceInfo.weekHighLow.minDate})&nbsp;&nbsp;&nbsp;&nbsp;{(sharesData[key] || { priceInfo: { "weekHighLow": { max: "" } } }).priceInfo.weekHighLow.max}({(sharesData[key] || { priceInfo: { "weekHighLow": { maxDate: "" } } }).priceInfo.weekHighLow.maxDate})</td>
              <td><textarea value={sharesData[key] ? JSON.stringify(sharesData[key]) : ""} onChange={(e) => updateSharesData(key, JSON.parse(e.target.value))}></textarea></td>
              <td>{(sharesData[key] || { priceInfo: { "lastPrice": "" } }).priceInfo.lastPrice}</td>
              <td>{parseFloat(row.subject ? row.subject.split(' Per ').length > 1 ? row.subject.split(' Per ')[0].trim().substring(row.subject.split(' Per ')[0].trim().lastIndexOf(' ')) : "0" : "") + parseFloat(row.subject ? row.subject.split(' Per ').length > 2 ? row.subject.split(' Per ')[1].trim().substring(row.subject.split(' Per ')[1].trim().lastIndexOf(' ')) : "0" : "") + parseFloat(row.subject ? row.subject.split(' Per ').length > 3 ? row.subject.split(' Per ')[2].trim().substring(row.subject.split(' Per ')[2].trim().lastIndexOf(' ')) : "0" : "")}</td>
              <td style={{ color: sharesData[key] && calculateROI(sharesData[key].priceInfo.lastPrice, row.subject) > 3 ? 'red' : sharesData[key] && calculateROI(sharesData[key].priceInfo.lastPrice, row.subject) > 2 ? 'orange' : sharesData[key] && calculateROI(sharesData[key].priceInfo.lastPrice, row.subject) > 1 ? 'yellow' : 'white', backgroundColor: sharesData[key] && calculateROI(sharesData[key].priceInfo.lastPrice, row.subject) > 4 ? 'white' : "black", fontWeight: sharesData[key] && calculateROI(sharesData[key].priceInfo.lastPrice, row.subject) > 4 ? 'bolder' : "normal" }}>{(sharesData[key] || { priceInfo: { "lastPrice": "" } }).priceInfo.lastPrice === "" ? "" : calculateROI(sharesData[key].priceInfo.lastPrice, row.subject)}</td>
              <td><a style={{ color: 'white' }} href={"https://www.nseindia.com/api/corporates-corporateActions?index=equities&symbol=" + row.symbol} target="_blank">{row.subject}</a></td>
              <td><input type="text" value={historyData[key] ? JSON.stringify(historyData[key]) : ""} onChange={(e) => updateHistoryData(key, JSON.parse(e.target.value))} /><br /><input type="button" style={{ display: historyData[key] ? 'table-cell' : 'none' }} value="View" onClick={(e) => showHistory(key)} /><input style={{ display: 'table-cell' }} type="button" value="Research" onClick={(e) => showResearch(key)} /></td>
              <td>{(sharesData[key] || { metadata: { "industry": "" } }).metadata.industry}</td>
              <td style={{ color: sharesData[key] && parseFloat(sharesData[key].metadata.pdSymbolPe) <= 25 ? 'green' : '' }}>{(sharesData[key] || { metadata: { "pdSymbolPe": "" } }).metadata.pdSymbolPe}</td>
              <td>{(sharesData[key] || { metadata: { "pdSectorPe": "" } }).metadata.pdSectorPe}</td>
              <td>{row.faceVal}</td>
              <td>{row.exDate}</td>
              <td>{(sharesData[key] || { metadata: { "listingDate": "" } }).metadata.listingDate}</td>
            </tr>)
          })}
        </table>
      </header>
    </div>
  );
}

export default App;
